var app = angular.module("app", ["ngCookies", "kendo.directives", "ngProgress", "ui.bootstrap", "ngRoute", "angular-fallback-image", "luegg.directives", "mc.resizer", "focus-if"])
	.config([
		"$routeProvider", "$compileProvider", "$httpProvider", "$locationProvider", function ($routeProvider, $compileProvider, $httpProvider, $locationProvider) {

			$locationProvider.hashPrefix('');
			$compileProvider.debugInfoEnabled(false);

			$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|callto|tel|chrome-extension|skype):/);

			$routeProvider
				.when("/login",
					{
						templateUrl: $.sharedAppDir + "/views/login.html"
					})
				.when("/",
					{
						templateUrl: $.sharedAppDir + "/views/state.html",
						controller: 'stateController'
					})
				.when('/execution/:workflowIdentifier/:transactionUserName?/:executeOnly?',
					{
						templateUrl: $.sharedAppDir + "/views/urlExecution.html",
						controller: 'urlExecutionController'
				})
				.otherwise({ redirectTo: "/" });

			$httpProvider.interceptors.push("webHttpInterceptor");

		}
	]).constant({
		appConfig: {
			APPLICATION_TYPE: 'WebClient',
			Events: {
				ENTER_KEY_PRESSED: 'enterKeyPressed'
			}
		}
	}).run([
		"sharedSessionService",
		"$location", "$rootScope", "localizationService",
		'$uibModalStack', 'fingerPrintService', 'openIdService', '$injector', function (sharedSessionService, $location, $rootScope, localizationService, $uibModalStack, fingerPrintService, openIdService, $injector) {
			//for use in html templates
			$rootScope.sharedAppDir = $.sharedAppDir;
			$rootScope.sharedImagesDir = $.sharedImagesDir;

			$rootScope.$on("$routeChangeStart", function(ev, next, curr) {

				$uibModalStack.dismissAll('redirect');
				var path = $location.path();

				if (path.indexOf("/id_token") === 0) {
					openIdService.handleCallback();
				} else {
					//second condition for transient user execution        
					if (sharedSessionService.exists() || (!!next.params && !!next.params.transactionUserName)) {

						if ($rootScope.urlPath) {
							var urlPath = $rootScope.urlPath;
							$rootScope.urlPath = null;
							$location.path(urlPath);
						} else {
							$location.path(path);
						}
					} else {
						sharedSessionService.reset();
						if (path !== '/login')
							$rootScope.urlPath = path;

						$location.path("/login");
					}
				}
			});

			fingerPrintService.prepare();
		}
	]);
